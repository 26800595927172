import { isEmpty } from "../utils";
import { i18n } from "../i18n";

export const constructFancyInfoWindowContent = (
  {
    dest_add = '',
    recipient_name: name = '',
    driver_name,
    visit_at,
    kind,
    visit_id = undefined,
    driver_id = 'null',
    route = undefined,
    company_name = undefined,
    region_str = undefined,
    earliest_time = undefined,
    latest_time = undefined,
    task: {
      destination_phone: phone,
      id: task_id,
      wp_order_id: wp_order_id = undefined
    }
  }
) => {
  return `<div id="popup-content" style="min-width: 250px">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <a target="_blank" href="/tasks/${task_id}" class="map-task-link" data-visit-id="${visit_id}"
        data-task-id=${task_id}>
        <span class="fa-light fa-plus-square"></span>
        ${task_id}
      </a>
      ${gon.allow_routes ? routesSelect(visit_id, route) : driversSelect(visit_id, driver_id, driver_name)}
    </div>
    ${!isEmpty(route)
    ? (
      `<div class="d-flex justify-content-between text-left">
          <span class="font-weight-bolder">
            ${!isEmpty(route) ? `${i18n.t('models.route.route')} ${route.name}: ` : ''}
          </span>
        </div>`
    )
    : ''}
    <div class="d-flex justify-content-between mb-2">
      <span><i class="fa-light fa-calendar mr-2"></i> ${visit_at}</span>
      <span>${kind ? i18n.t(`models.visit.kind_enum.${kind}`) : ''}</span>
    </div>
    <div class="d-flex justify-content-between">
      ${gon.allow_companies && company_name ? `<span class="mb-2"><i class="fa-light fa-bank mr-2"></i> ${company_name}</span>` : ''}
      ${wp_order_id ? `<span class="mb-2"><i class="fa-light fa-box mr-2"></i> ${wp_order_id}</span>` : ''}
    </div>
    <div class="d-flex justify-content-between">
      ${phone ? `<span class="mb-2"><i class="fa-light fa-phone mr-2"></i> ${phone}</span>` : ''}
      ${name ? `<span class="mb-2"><i class="fa-light fa-user mr-2"></i> ${name}</span>` : ''}
    </div>
    ${earliest_time || latest_time ? (
      `<div class="d-flex justify-content-between mb-2">
        <span><i class="fa-light fa-clock mr-2"></i>
          ${ earliest_time && latest_time ? `${earliest_time} - ${latest_time}` : earliest_time || latest_time }
        </span>
      </div>`
    ) : '' }
    ${dest_add ? (
      `<div class="d-flex justify-content-between mb-2">
        <span><i class="fa-light fa-map-pin mr-4"></i> ${dest_add}</span>
      </div>`
    ) : ''}
    ${region_str ?
    `<div class="d-flex justify-content-between">
        <span class="mb-2"><i class="fa-light fa-map mr-2"></i> ${region_str} </span>
      </div>`
    : ''}
   </div>`
}

const driversSelect = (visitId, driverId, driverName) => (
  visitId ?
    `<div class="select2-small position-relative">
            <select class="popup-select" data-current-id="${driverId}" data-target-id="${visitId}"></select>
            <i class="fa fa-refresh fa-spin fa-fw margin-bottom d-none spinner"></i>
          </div>` : `<span><i class="fa fa-truck mr-2"></i> ${driverName}</span>`
)
const routesSelect = (visitId, route) => (
  visitId ?
    `<div class="select2-small position-relative">
      <select class="popup-select" data-current-id="${route.id}" data-target-id="${visitId}"></select>
      <i class="fa fa-refresh fa-spin fa-fw margin-bottom d-none spinner"></i>
    </div>` : `<span><i class="fa fa-truck mr-2"></i> ${route.name}</span>`
)

export const visitPopup = (visit) => (
  `<div id="popup-content" style="min-width: 250px">
     ${visitPopupContent(visit)}
   </div>`
)

export const unitedVisitsPopup = (visits) => (
  `<div id="popup-content" style="min-width: 250px">
     ${visits.map(visit => visitPopupContent(visit)).join('<hr />')}
   </div>`
)

const visitPopupContent = (
  {
    visit_id = undefined,
    dest_add = '',
    region_str = '',
    recipient_name: name = '',
    kind,
    company_name = undefined,
    order_in_route = undefined,
    task: {
      destination_phone: phone,
      id: task_id,
      wp_order_id: wp_order_id = undefined
    }
  }
) => (
  `
    <div class="d-flex justify-content-between align-items-center mb-2">
      <a target="_blank" href="/tasks/${task_id}" class="map-task-link" data-visit-id="${visit_id}"
        data-task-id=${task_id}>
        <span class="fa-light fa-plus-square"></span>
        ${task_id} ${order_in_route ? ` - ${order_in_route}` : ''}
      </a>
    </div>
    ${dest_add ?
    (`<div class="d-flex justify-content-between text-left">
        <span class="font-weight-bolder"> ${dest_add} </span>
        <span>${kind ? i18n.t(`models.visit.kind_enum.${kind}`) : ''}</span>
      </div>`)
    : ''}
    <div class="d-flex justify-content-between">
      ${company_name ? `<span class="mb-2"><i class="fa-light fa-bank mr-2"></i> ${company_name}</span>` : ''}
      ${wp_order_id ? `<span class="mb-2">${wp_order_id}</span>` : ''}
    </div>
    <div class="d-flex justify-content-between">
      ${phone ? `<span class="mb-2"><i class="fa-light fa-phone mr-2"></i> ${phone}</span>` : ''}
      ${name ? `<span class="mb-2"><i class="fa-light fa-user mr-2"></i> ${name}</span>` : ''}
    </div>
    ${region_str ?
    `<div class="d-flex justify-content-between">
      <span class="mb-2"><i class="fa-light fa-map mr-2"></i> ${region_str} </span>
    </div>`
    : ''}
  `
)

export const constructFancyInfoWindowContent3 = (
  {
    dest_add = '',
    recipient_name: name = '',
    driver_name,
    visit_at,
    kind,
    visit_id,
    driver_id = 'null',
    route,
    company_name,
    region_str,
    task: {
      destination_phone: phone,
      id: task_id,
      wp_order_id: wp_order_id,
      weight,
      volume,
      surface_quantity
    }
  }
) => {
  return `<div id="popup-content" style="min-width: 250px">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <a target="_blank" href="/tasks/${task_id}">
        <span class="fa-light fa-plus-square"></span>
        ${task_id}
      </a>
      ${gon.allow_routes ? '' : driversSelect(visit_id, driver_id, driver_name)}
    </div>
    ${dest_add || !isEmpty(route)
    ? (
      `<div class="d-flex justify-content-between text-left">
          <span class="font-weight-bolder">
            ${!isEmpty(route) ? `${i18n.t('models.route.route')} ${route.name}: ` : ''}
            ${dest_add}
          </span>
        </div>`
    )
    : ''}
    <div class="d-flex justify-content-between mb-2">
      <span><i class="fa-light fa-calendar mr-2"></i> ${visit_at}</span>
      <span>${kind ? i18n.t(`models.visit.kind_enum.${kind}`) : ''}</span>
    </div>
    <div class="d-flex justify-content-between">
      ${gon.allow_companies && company_name ? `<span class="mb-2"><i class="fa-light fa-bank mr-2"></i> ${company_name}</span>` : ''}
      ${wp_order_id ? `<span class="mb-2">${wp_order_id}</span>` : ''}
    </div>
    <div class="d-flex justify-content-between">
      ${phone ? `<span class="mb-2"><i class="fa-light fa-phone mr-2"></i> ${phone}</span>` : ''}
      ${name ? `<span class="mb-2"><i class="fa-light fa-user mr-2"></i> ${name}</span>` : ''}
    </div>
    ${region_str ?
    `<div class="d-flex justify-content-between">
        <span class="mb-2"><i class="fa-light fa-map mr-2"></i> ${region_str} </span>
      </div>`
    : ''}
    ${weight ?
    `<div class="d-flex justify-content-between">
        <span class="mb-2">
          <i class="fa-light fa-weight-scale mr-2" data-toggle="tooltip"
           data-placement="left" title="${i18n.t('models.task.weight')}"></i>
          ${weight}
        </span>
      </div>`
    : ''}
    ${volume ?
    `<div class="d-flex justify-content-between">
        <span class="mb-2">
          <i class="fa-light fa-weight-scale mr-2" data-toggle="tooltip"
           data-placement="left" title="${i18n.t('models.task.volume')}"></i>
          ${volume}
        </span>
      </div>`
    : ''}
    ${surface_quantity ?
    `<div class="d-flex justify-content-between">
        <span class="mb-2">
          <i class="fa-light fa-weight-scale mr-2" data-toggle="tooltip"
           data-placement="left" title="${i18n.t('models.task.surface_quantity')}"></i>
          ${surface_quantity}
        </span>
      </div>`
    : ''}
   </div>`
}
